import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  RawTranscript,
  ExtensionTranscriptSummary,
  LabelConfig,
  sortBlocks,
} from '@tactiq/model';
import { AnyMeeting, BasicMeeting, FullMeeting } from '../../models/meeting';
import { TranscriptChanges } from '../../models/transcript';
import keyBy from 'lodash/keyBy';
import {
  MeetingKit,
  MeetingKitItem,
  MeetingReach,
  MeetingType,
  MeetingKitItemOutputType,
  Tag,
  UserMeetingKits,
  Space,
  MeetingFullFieldsFragment,
  MeetingBasicFieldsFragment,
} from '../../graphql/operations';

import { clearCache } from '../../graphql/client';
import { signOut } from './user';
import { obfuscate } from '../../helpers/obfuscate';
import { produce } from 'immer';

export const gotLabels = createAction<LabelConfig[]>('GOT_LABELS');

export const addLabel = createAction<LabelConfig>('ADD_LABEL');

export const updateLabel = createAction<LabelConfig>('UPDATE_LABEL');

export const removeLabel = createAction<string>('REMOVE_LABEL');

export const setMeetingKit = createAction<MeetingKit>('SET_MEETING_KIT');

export const removeMeetingKit = createAction<string>('REMOVE_MEETING_KIT');

export const setTranscriptUploadProgress = createAction<AnyMeeting>(
  'SET_TRANSCRIPT_UPLOAD_PROGRESS'
);

export const setMeetingPagesToLoad = createAction<number>(
  'SET_MEETING_PAGES_TO_LOAD'
);

export const updateMeetingKitFromSource = createAction<MeetingKit>(
  'UPDATE_MEETING_KIT_FROM_SOURCE'
);

export const setMeetingKitItem = createAction<{
  kitId: string;
  item: MeetingKitItem;
}>('SET_MEETING_KIT_ITEM');

export const removeMeetingKitItem = createAction<{
  kitId: string;
  itemId: string;
}>('REMOVE_MEETING_KIT_ITEM');

export const gotMeetingKits = createAction<UserMeetingKits>('GOT_MEETING_KITS');

export const restoreMeeting = createAction<string>('RESTORE_MEETING');

export const gotMeetingTags = createAction<Tag[]>('GOT_MEETING_TAGS');

export const gotSpace = createAction<Space>('GOT_SPACE');

export const spaceArchived = createAction<{ spaceId: string }>(
  'SPACE_ARCHIVED'
);

export const gotMeetings = createAction<{
  type: MeetingType;
  spaceId?: string;
  meetings: BasicMeeting[];
}>('GOT_MEETINGS');

export const gotTranscriptSummaries = createAction<
  ExtensionTranscriptSummary[]
>('GOT_TRANSCRIPT_SUMMARIES');

export const setAiOutputContent = createAction<{
  meetingId: string;
  outputPromptId: string;
  content: string;
}>('SET_AI_OUTPUT_CONTENT');

export const archiveMeeting = createAction<string>('ARCHIVE_MEETING');

export const addMeetingToSpace = createAction<{
  meetingId: string;
  spaceId: string;
  // This will go away once migrate team folder to space
  teamId?: string;
}>('ADD_MEETING_TO_SPACE');

export const removeMeetingFromSpace = createAction<{
  meetingId: string;
  spaceId: string;
}>('REMOVE_MEETING_FROM_SPACE');

export const addLabelToMeeting = createAction<{
  meetingId: string;
  labelId: string;
}>('ADD_LABEL_TO_MEETING');

export const removeLabelFromMeeting = createAction<{
  meetingId: string;
  labelId: string;
}>('REMOVE_LABEL_FROM_MEETING');

export const deleteMeeting = createAction<string>('DELETE_MEETING');

export const gotRawTranscript = createAction<{
  meetingId: string;
  transcript: RawTranscript;
}>('GOT_RAW_TRANSCRIPT');

export const gotArePermissionsGranted = createAction<boolean>(
  'GOT_ARE_PERMISSIONS_GRANTED'
);

export const createInvitationLink = createAction('CREATE_INVITATION_LINK');

export const getTranscript = createAction<{ meetingId: string }>(
  'GET_TRANSCRIPT'
);

export const gotMeeting = createAction<FullMeeting>('GOT_MEETING');

export const setLoadingMeetingData = createAction<boolean>(
  'SET_LOADING_MEETING_DATA'
);

export const updateTranscriptNotes = createAction<{
  meetingId: string;
  content: string;
  updatedAt: number;
}>('UPDATE_TRANSCRIPT_NOTES');

export const updateUnauthorizedTranscripts = createAction<
  Record<string, boolean>
>('UPDATE_UNAUTHORIZED_TRANSCRIPT');

export const updateNotFoundTranscripts = createAction<Record<string, boolean>>(
  'UPDATE_NOT_FOUND_TRANSCRIPT'
);

export const changeTranscript = createAction<{
  meetingId: string;
  changes: TranscriptChanges;
}>('CHANGE_TRANSCRIPT');

export const updateExtensionAvailability = createAction<boolean>(
  'UPDATE_EXTENSION_AVAILABILITY'
);

export const updateBillingProcessingStatus = createAction<boolean>(
  'UPDATE_BILLING_PROCESSING_STATUS'
);

export const setFilteredParticipants = createAction<{
  meetingId: string;
  participants: string[];
}>('SET_FILTERED_PARTICIPANTS');

export const saveGlobalState = createAction('SAVE_GLOBAL_STATE');

export const gotScreenshots = createAction<{
  meetingId: string;
  screenshots: Record<string, string>;
}>('GOT_SCREENSHOTS');

export enum RateUsModalSource {
  MY_MEETING_LIST = 'my-meeting-list',
  SHARED_MEETING_LIST = 'shared-meeting-list',
  SEND_TEAM_INVITATION = 'send-team-invitation',
  SHARE_MEETING = 'share-meeting',
}

export const showRateUsModal =
  createAction<RateUsModalSource>('SHOW_RATE_US_MODAL');

export const hideRateUsModal = createAction('HIDE_RATE_US_MODAL');

export const showCreateSpaceDialog = createAction('SHOW_CREATE_SPACE_DIALOG');

export const hideCreateSpaceDialog = createAction('HIDE_CREATE_SPACE_DIALOG');

export const showReasonForSignupModal = createAction(
  'SHOW_REASON_FOR_SIGNUP_MODAL'
);

export const hideReasonForSignupModal = createAction(
  'HIDE_REASON_FOR_SIGNUP_MODAL'
);

export const showTeamShareModal = createAction('SHOW_TEAM_SHARE_MODAL');

export const hideTeamShareModal = createAction('HIDE_TEAM_SHARE_MODAL');

export const setUserLoaded = createAction<boolean>('SET_USER_LOADED');

export const setStartedUp = createAction('SET_STARTED_UP');

export const setNeedAuthentication = createAction('SET_NEED_AUTHENTICATION');

export const setFeatureFlags =
  createAction<GlobalState['featureFlags']>('SET_FEATURE_FLAGS');

export enum PermissionsGrantedStatus {
  UNKNOWN = 0,
  GRANTED = 1,
  MISSING = 2,
}

export interface GlobalState {
  startedUp: boolean;
  userLoaded: boolean;
  signedOut?: boolean;
  needAuthentication: boolean;

  labels: LabelConfig[];
  meetingKits: UserMeetingKits;
  meetingTags: Tag[];
  meetingPages: number;
  mightHaveMoreOwnMeetings: boolean;
  activeTranscriptUploads: AnyMeeting[];
  meetings: {
    my: Record<string, BasicMeeting>;
    archived: Record<string, BasicMeeting>;
    sharedWithMe: Record<string, BasicMeeting>;
    spaces: Record<string, Record<string, BasicMeeting> | undefined>;

    details: Record<string, FullMeeting | undefined>;
  };
  archivedMeetings: AnyMeeting[];
  transcriptSummaries: ExtensionTranscriptSummary[];
  transcriptsUnauthorized: Record<string, boolean>;
  transcriptsNotFound: Record<string, boolean>;
  screenshotUrls?: Record<string, string>;
  rawTranscripts: Record<string, RawTranscript | undefined>;
  operations: {
    isLoadingMeetingData?: boolean;
    isCreatingInvitationLink?: boolean;
    isUpdatingTranscript?: boolean;
    isProcessingBilling?: boolean;
    isLoadingMeetings?: boolean;
  };
  integrations: {
    arePermissionsGranted: PermissionsGrantedStatus;
  };
  isExtensionAvailable: boolean;
  ui: {
    showRateUsModalFrom: false | RateUsModalSource;
    showReasonForSignupModal: boolean;
    showTeamSharingModal: boolean;
    filteredParticipants: Record<string, string[]>;
    showCreateSpaceDialog: boolean;
  };
  featureFlags: {
    isEnabled: false;
  };
  spaces: Record<string, Space>;
  user: {
    onboarding: {
      GoogleMeet: {
        isSelected: boolean;
      };
      Zoom: {
        isSelected: boolean;
      };
      Webex: {
        isSelected: boolean;
      };
      Teams: {
        isSelected: boolean;
      };
    };
  };
}

const defaultState: GlobalState = {
  startedUp: false,
  userLoaded: false,
  needAuthentication: false,

  labels: [],
  meetingKits: { system: {} as MeetingKit, used: [], explore: [] },
  meetingTags: [],
  meetingPages: 1,
  mightHaveMoreOwnMeetings: false,
  activeTranscriptUploads: [],
  meetings: {
    my: {},
    archived: {},
    sharedWithMe: {},
    spaces: {},

    details: {},
  },
  archivedMeetings: [],
  transcriptSummaries: [],
  transcriptsUnauthorized: {},
  transcriptsNotFound: {},
  rawTranscripts: {},
  operations: {
    isLoadingMeetings: true,
  },
  integrations: {
    arePermissionsGranted: PermissionsGrantedStatus.UNKNOWN,
  },
  isExtensionAvailable: true,
  ui: {
    showReasonForSignupModal: false,
    showRateUsModalFrom: false,
    showTeamSharingModal: false,
    filteredParticipants: {},
    showCreateSpaceDialog: false,
  },
  featureFlags: {
    isEnabled: false,
  },
  spaces: {},
  user: {
    onboarding: {
      GoogleMeet: {
        isSelected: false,
      },
      Zoom: {
        isSelected: false,
      },
      Webex: {
        isSelected: false,
      },
      Teams: {
        isSelected: true,
      },
    },
  },
};

/**
 *
 * @param a
 * @param a.name
 * @param b
 * @param b.name
 */
function byName(a: { name: string }, b: { name: string }) {
  return a.name.localeCompare(b.name);
}

/**
 *
 * @param a
 * @param a.order
 * @param b
 * @param b.order
 */
function byOrder(a: { order: number }, b: { order: number }) {
  return a.order - b.order;
}

// This function returns all instances of a meeting (found in 'my meetings',
// 'shared with me', or 'space view') for an optimistic UI update.
function getMeetingsToUpdate(state: GlobalState, meetingId: string) {
  return [
    state.meetings.my[meetingId],
    state.meetings.sharedWithMe[meetingId],
    ...Object.values(state.meetings.spaces)
      .map((s) => s && s[meetingId])
      .filter(Boolean),
  ].filter(Boolean);
}

export default createReducer(defaultState, (builder) => {
  builder
    .addCase(gotMeetingTags, (state, { payload }) => {
      state.meetingTags = payload;
    })
    .addCase(gotLabels, (state, { payload }) => {
      state.labels = payload;
    })
    .addCase(gotMeetingKits, (state, { payload }) => {
      state.meetingKits = payload;

      state.meetingKits.system.items.sort(byOrder);

      state.meetingKits.used.sort(byName);
      for (const kit of state.meetingKits.used) {
        kit.items.sort(byOrder);
      }

      state.meetingKits.explore.sort(byName);
      for (const kit of state.meetingKits.explore) {
        kit.items.sort(byOrder);
      }
    })
    .addCase(setMeetingKit, (state, { payload }) => {
      const idx = state.meetingKits.used.findIndex(
        (kit) => kit.id === payload.id
      );
      if (idx > -1) {
        state.meetingKits.used.splice(idx, 1, payload);
      } else {
        state.meetingKits.used = [...state.meetingKits.used, payload];
      }

      state.meetingKits.used.sort(byName);
    })
    .addCase(setMeetingKitItem, (state, { payload }) => {
      const kit = state.meetingKits.used.find((k) => k.id === payload.kitId);
      if (!kit) return;

      const idx = kit.items.findIndex((item) => item.id === payload.item.id);
      if (idx > -1) {
        kit.items.splice(idx, 1, payload.item);
      } else {
        kit.items = [...kit.items, payload.item];
      }

      kit.items.sort(byOrder);
    })
    .addCase(removeMeetingKit, (state, { payload }) => {
      const idx = state.meetingKits.used.findIndex((kit) => kit.id === payload);
      if (idx > -1) {
        state.meetingKits.used.splice(idx, 1);
      }
    })
    .addCase(removeMeetingKitItem, (state, { payload }) => {
      const kit = state.meetingKits.used.find((k) => k.id === payload.kitId);
      if (!kit) return;

      const idx = kit.items.findIndex((item) => item.id === payload.itemId);
      if (idx > -1) {
        kit.items.splice(idx, 1);
      }
    })
    .addCase(addLabel, (state, { payload }) => {
      state.labels.push(payload);
    })
    .addCase(updateLabel, (state, { payload }) => {
      const label = state.labels.find((x) => x.id === payload.id);

      if (label) {
        Object.assign(label, payload);
      }
    })
    .addCase(removeLabel, (state, { payload }) => {
      state.labels.splice(
        state.labels.findIndex((x) => x.id === payload),
        1
      );
    })
    .addCase(setTranscriptUploadProgress, (state, { payload }) => {
      const { id, uploadProgress } = payload;
      const uploadingMeeting = state.activeTranscriptUploads.find(
        (m) => m.id === id
      );
      if (uploadingMeeting) {
        if (uploadProgress === 100) {
          state.activeTranscriptUploads = state.activeTranscriptUploads.filter(
            (m) => m.id !== id
          );
        } else {
          uploadingMeeting.uploadProgress = uploadProgress;
        }
      } else {
        // If upload is still active but not in the list, add it
        if (uploadProgress !== 100) {
          state.activeTranscriptUploads = [
            ...state.activeTranscriptUploads,
            payload,
          ];
        }
      }
    })
    .addCase(setLoadingMeetingData, (state, { payload }) => {
      state.operations.isLoadingMeetingData = payload;
    })
    .addCase(setAiOutputContent, (state, { payload }) => {
      const outputs = state.meetings.details[payload.meetingId]?.aiOutputs;
      if (!outputs) {
        return;
      }
      const output = outputs.find(
        (o) => o.requestedAt.toString() === payload.outputPromptId
      );
      if (
        output &&
        output.content &&
        (output.contentType === MeetingKitItemOutputType.TEXT ||
          output.contentType === MeetingKitItemOutputType.MARKDOWN)
      ) {
        (output.content as { text: string }).text = payload.content;
      }
    })
    .addCase(gotMeetings, (state, { payload }) => {
      const { type, spaceId, meetings } = payload;
      const debug = isInDebugMode();

      const meetingsHash = keyBy(
        debug
          ? meetings.map((m) =>
              obfuscateMeetingData<MeetingBasicFieldsFragment>(m)
            )
          : meetings,
        'id'
      );

      switch (type) {
        case MeetingType.MYMEETINGS:
          state.meetings.my = meetingsHash;
          break;
        case MeetingType.ARCHIVED:
          state.meetings.archived = meetingsHash;
          break;
        case MeetingType.SHAREDWITHME:
          state.meetings.sharedWithMe = meetingsHash;
          break;
        case MeetingType.INSPACE:
          if (!spaceId) return;
          state.meetings.spaces[spaceId] = meetingsHash;
          break;
      }
    })
    .addCase(gotTranscriptSummaries, (state, { payload }) => {
      state.transcriptSummaries = payload;
    })
    .addCase(updateUnauthorizedTranscripts, (state, { payload }) => {
      state.transcriptsUnauthorized = payload;
    })
    .addCase(updateNotFoundTranscripts, (state, { payload }) => {
      state.transcriptsNotFound = payload;
    })
    .addCase(gotMeeting, (state, { payload }) => {
      state.meetings.details[payload.id] = isInDebugMode()
        ? obfuscateMeetingData<MeetingFullFieldsFragment>(payload)
        : payload;

      const {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        user,
        recordings,
        noSharingPrompts,
        comments,
        views,
        changes,
        aiOutputs,
        /* eslint-enable @typescript-eslint/no-unused-vars */
        ...basicMeeting
      } = payload;

      // const meeting = payload as MeetingBasicFieldsFragment;
      if (state.meetings.my[payload.id]) {
        state.meetings.my[payload.id] = basicMeeting;
      }
      if (state.meetings.sharedWithMe[payload.id]) {
        state.meetings.sharedWithMe[payload.id] = basicMeeting;
      }
      const meetingSpaces = payload.permissions?.allow.spaces ?? [];
      for (const spaceId of meetingSpaces) {
        const space = state.meetings.spaces[spaceId];
        if (space && !space[payload.id]) {
          space[payload.id] = basicMeeting;
        }
      }

      for (const spaceId in state.meetings.spaces) {
        const space = state.meetings.spaces[spaceId];
        if (space && space[payload.id] && !meetingSpaces.includes(spaceId)) {
          delete space[payload.id];
        }
      }
    })
    .addCase(addLabelToMeeting, (state, { payload }) => {
      getMeetingsToUpdate(state, payload.meetingId).forEach((meeting) => {
        const label = state.labels.find((l) => l.id === payload.labelId);
        if (meeting && label) {
          meeting.labels = [...meeting.labels, { ...label, isAuto: false }];
        }
      });
    })
    .addCase(removeLabelFromMeeting, (state, { payload }) => {
      getMeetingsToUpdate(state, payload.meetingId).forEach((meeting) => {
        if (meeting) {
          meeting.labels = meeting.labels.filter(
            (l) => l.id !== payload.labelId
          );
        }
      });
    })
    .addCase(archiveMeeting, (state, { payload }) => {
      delete state.meetings.my[payload];
      for (const spaceId in state.meetings.spaces) {
        const space = state.meetings.spaces[spaceId];
        if (space) {
          delete space[payload];
        }
      }
    })
    .addCase(addMeetingToSpace, (state, { payload }) => {
      getMeetingsToUpdate(state, payload.meetingId).forEach((meeting) => {
        if (!meeting) {
          return;
        }
        if (meeting.permissions) {
          if (payload.spaceId === 'team') {
            meeting.permissions.allow.teams = payload?.teamId
              ? [payload?.teamId]
              : [];
          } else {
            meeting.permissions.allow.spaces = [
              payload.spaceId,
              ...(meeting.permissions.allow.spaces ?? []),
            ];
          }
        } else {
          meeting.permissions = {
            allow: {
              teams: payload?.teamId ? [payload?.teamId] : [],
              spaces: [payload.spaceId],
              emails: [],
              reach: MeetingReach.RESTRICTED,
              settings: {
                isSharingDetails: true,
                isSharingHighlights: true,
                isSharingNotes: true,
                isSharingTranscript: true,
              },
            },
          };
        }
      });
    })
    .addCase(removeMeetingFromSpace, (state, { payload }) => {
      const space = state.meetings.spaces[payload.spaceId];
      if (space) {
        delete space[payload.meetingId];
      }

      getMeetingsToUpdate(state, payload.meetingId).forEach((meeting) => {
        if (meeting && meeting.permissions) {
          meeting.permissions.allow.teams = [];
          meeting.permissions.allow.spaces =
            meeting.permissions.allow.spaces?.filter(
              (id) => id !== payload.spaceId
            ) ?? [];
        }
      });
    })
    .addCase(gotRawTranscript, (state, { payload }) => {
      state.rawTranscripts[payload.meetingId] = isInDebugMode()
        ? obfuscateTranscript(payload.transcript)
        : payload.transcript;
    })
    .addCase(createInvitationLink, (state) => {
      state.operations.isCreatingInvitationLink = true;
    })
    .addCase(gotArePermissionsGranted, (state, { payload }) => {
      state.integrations.arePermissionsGranted = payload
        ? PermissionsGrantedStatus.GRANTED
        : PermissionsGrantedStatus.MISSING;
    })
    .addCase(gotScreenshots, (state, { payload }) => {
      if (!state.screenshotUrls) {
        state.screenshotUrls = {};
      }

      for (const ss of Object.keys(payload.screenshots)) {
        state.screenshotUrls[`${payload.meetingId}/${ss}`] =
          payload.screenshots[ss];
      }
    })
    .addCase(updateTranscriptNotes, (state, { payload }) => {
      const transcript = state.rawTranscripts[payload.meetingId];

      if (transcript) {
        transcript.notes = { content: payload.content, version: Date.now() };
        transcript.updatedAt = payload.updatedAt;
      }
    })
    .addCase(changeTranscript, (state, { payload: { meetingId, changes } }) => {
      const transcript = state.rawTranscripts[meetingId];

      if (transcript) {
        transcript.blocks = sortBlocks([
          ...transcript.blocks.filter(
            (b) => !changes.oldMessageIds.includes(b.messageId)
          ),
          ...changes.newBlocks,
        ]);

        transcript.updatedAt = Date.now();
      }
    })
    .addCase(updateExtensionAvailability, (state, { payload }) => {
      state.isExtensionAvailable = payload;
    })
    .addCase(updateBillingProcessingStatus, (state, { payload }) => {
      state.operations.isProcessingBilling = payload;
    })
    .addCase(restoreMeeting, (state, { payload }) => {
      const meeting = state.meetings.archived[payload];
      delete state.meetings.archived[payload];
      state.meetings.my[payload] = meeting;
    })
    .addCase(deleteMeeting, (state, { payload }) => {
      // we really only need to evict ROOT_QUERY["meetings:{\"type\":\"Archived\"}"]
      // but for some reason that din't work for me
      clearCache();
      delete state.meetings.archived[payload];
    })
    .addCase(showRateUsModal, (state, { payload }) => {
      state.ui.showRateUsModalFrom = payload;
    })
    .addCase(hideRateUsModal, (state) => {
      state.ui.showRateUsModalFrom = false;
    })
    .addCase(showCreateSpaceDialog, (state) => {
      state.ui.showCreateSpaceDialog = true;
    })
    .addCase(hideCreateSpaceDialog, (state) => {
      state.ui.showCreateSpaceDialog = false;
    })
    .addCase(showReasonForSignupModal, (state) => {
      state.ui.showReasonForSignupModal = true;
    })
    .addCase(hideReasonForSignupModal, (state) => {
      state.ui.showReasonForSignupModal = false;
    })
    .addCase(showTeamShareModal, (state) => {
      state.ui.showTeamSharingModal = true;
    })
    .addCase(hideTeamShareModal, (state) => {
      state.ui.showTeamSharingModal = false;
    })
    .addCase(setFilteredParticipants, (state, { payload }) => {
      state.ui.filteredParticipants[payload.meetingId] = payload.participants;
    })
    .addCase(setUserLoaded, (state, { payload }) => {
      state.userLoaded = payload;
    })
    .addCase(setStartedUp, (state) => {
      state.startedUp = true;
    })
    .addCase(signOut, (state) => {
      state.signedOut = true;
    })
    .addCase(setNeedAuthentication, (state) => {
      state.needAuthentication = true;
    })
    .addCase(setMeetingPagesToLoad, (state, { payload }) => {
      state.meetingPages = payload;
    })
    .addCase(setFeatureFlags, (state, { payload }) => {
      state.featureFlags = payload;
    })
    .addCase(gotSpace, (state, { payload }) => {
      state.spaces = {
        ...state.spaces,
        [payload.id]: payload,
      };
    })
    .addCase(spaceArchived, (state, { payload }) => {
      state.spaces = {
        ...state.spaces,
        [payload.spaceId]: {
          ...state.spaces[payload.spaceId],
          isArchived: true,
        },
      };
    });
});

const isInDebugMode = () => localStorage.getItem('TACTIQ_DEBUG') === 'true';

const obfuscateMeetingData = <
  TMeeting extends MeetingBasicFieldsFragment | MeetingFullFieldsFragment,
>(
  meeting: TMeeting
): TMeeting => {
  const next = produce(meeting, (draft) => {
    draft.title = obfuscate(draft.title);
    draft.participants.forEach((p) => {
      p.name = obfuscate(p.name);
    });

    if (draft.calendarData?.organizer) {
      draft.calendarData.organizer.displayName = obfuscate(
        draft.calendarData?.organizer.displayName
      );
      draft.calendarData.organizer.email = obfuscate(
        draft.calendarData?.organizer.email
      );
    }

    if (draft.calendarData?.creator) {
      draft.calendarData.creator.displayName = obfuscate(
        draft.calendarData?.creator.displayName
      );
      draft.calendarData.creator.email = obfuscate(
        draft.calendarData?.creator.email
      );
    }

    if (draft.calendarData?.participants) {
      draft.calendarData.participants.forEach((p) => {
        p.displayName = obfuscate(p.displayName);
        p.email = obfuscate(p.email);
      });
    }

    // This is to keep the TS compiler happy
    if ('user' in draft && draft.user) {
      draft.user.displayName = obfuscate(draft.user.displayName);
    }

    if ('aiOutputs' in draft) {
      draft.aiOutputs = [];
    }
  });
  return next;
};

const obfuscateTranscript = (transcript: RawTranscript): RawTranscript => {
  const next = produce(transcript, (draft) => {
    draft.blocks.forEach((b) => {
      b.speakerName = obfuscate(b.speakerName);
      b.transcript = obfuscate(b.transcript);
    });
  });
  return next;
};
