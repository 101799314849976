import { RumGlobal } from '@datadog/browser-rum';

export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
  CRITICAL = 4,
}

export interface LoggerConfig {
  logLevel: LogLevel;
  serviceName: string | undefined;
  serviceVersion?: string;
  parameters: Record<string, string> & { uid?: string; anonymousId?: string };
}

export type ErrorLike = Error | (Error & Record<string, unknown>);

export class Logger {
  private config: LoggerConfig = {
    serviceName: 'tactiq-prod-au-web',
    logLevel: LogLevel.INFO,
    parameters: {
      uid: 'anonymous',
    },
  };

  private getDD() {
    if (
      !(typeof self !== 'undefined' && 'registration' in self) &&
      typeof window !== 'undefined' &&
      'DD_RUM' in window
    ) {
      return window.DD_RUM as RumGlobal;
    }
  }

  configure(config: Partial<LoggerConfig>): void {
    this.config = {
      ...this.config,
      ...config,
      parameters: {
        ...this.config.parameters,
        ...config.parameters,
      },
    };
    const dd = this.getDD();
    if (dd && this.config.parameters.uid) {
      dd.setUser({ id: this.config.parameters.uid });
    }
  }

  info(...data: unknown[]): void {
    this.write(LogLevel.INFO, ...data);
  }

  debug(...data: unknown[]): void {
    this.write(LogLevel.DEBUG, ...data);
  }

  warn(...data: unknown[]): void {
    this.write(LogLevel.WARN, ...data);
  }

  error: (
    e: ErrorLike,
    extraAttributes?: Record<string, string | number | boolean>
  ) => void = (e, extraAttributes) => {
    // biome-ignore lint: noConsole
    console.error('[Tactiq]: ', e);

    const dd = this.getDD();
    if (dd) {
      const { componentStack, ...rest } = extraAttributes ?? {};
      if (componentStack) {
        e.stack = componentStack as string;
      }
      dd.addError(e, rest);
    }
  };

  private write(level: LogLevel, ...data: unknown[]): void {
    if (level >= this.config.logLevel) {
      // biome-ignore lint: noConsole
      console.log(`[Tactiq]: `, ...data);
    }
  }
}

const logger = new Logger();

export default logger;
