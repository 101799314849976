import { getAuth } from 'firebase/auth';

export function getToken(mandatory = true): Promise<string> | undefined {
  if ((window as any).__STORYBOOK_PREVIEW__) {
    return Promise.resolve('my-token');
  }

  if (mandatory && !getAuth().currentUser) {
    throw new Error('The user is not logged in.');
  }

  return getAuth().currentUser?.getIdToken();
}
