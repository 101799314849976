import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  from,
  split,
  Cache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { logger } from '@tactiq/model';
import { createClient } from 'graphql-ws';
import { enqueueSnackbar } from 'notistack';
import { getToken } from '../helpers/firebase/auth';
import { GRAPHQL_WS_URL, isProduction } from '../helpers/firebase/config';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import uniqBy from 'lodash/uniqBy';
import { store } from '../redux/store';
import { setNeedAuthentication } from '../redux/modules/global';
import { forceReload } from '../helpers/force-reload';
import { trackWebEvent } from '../helpers/analytics';

const wsClient = createClient({
  url: GRAPHQL_WS_URL,
  connectionParams: async () => {
    return {
      authorization: await getToken(true),
    };
  },
  shouldRetry: () => true,
  retryAttempts: 30,
  on: {
    closed: (event) => {
      if ((event as { code: number }).code === 4403) {
        store.dispatch(setNeedAuthentication());
      }
    },
  },
});

const wsLink = new GraphQLWsLink(wsClient);

const httpLink = createHttpLink({
  uri: (process.env.API_BASE_URL || window.location.origin) + '/api/2/graphql',
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken(true);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-ga-cookie': document.cookie.match(/_ga=([^;]+)/)?.[1] ?? '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions?.code === 'USERERROR') {
          enqueueSnackbar(message, { variant: 'ERROR' });
          if (response) {
            response.errors = response?.errors?.filter(
              (e) => e.message !== message
            );
          }
        } else {
          logger.error(
            new Error(
              `[GraphQL Web Client error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );

          const userVersion = store.getState().user.buildVersion ?? '';
          const currentVersion = process.env.BUILD_SHA ?? '';
          if (userVersion !== currentVersion) {
            forceReload('TWO_MINUTES', () => {
              // biome-ignore lint: noConsole
              console.log(
                'Reloading the page because of a graphql error',
                currentVersion,
                userVersion
              );
              trackWebEvent('Reloading the page because of a graphql error', {
                currentVersion,
                userVersion,
              });
            });
          }
        }
      });

    if (networkError) {
      logger.error(
        new Error(
          `[GraphQL Web Client Network error]: ${networkError}, Operation: ${
            operation.operationName
          }, Response ${response?.data ? 'has data' : 'does not have data'}`
        )
      );
    }
  }
);

const cache = new InMemoryCache({
  typePolicies: {
    // Workflow Definitions have id fields but they are not globally unique.
    // We need to turn off normalization for so we don't get bleeding across
    WorkflowDefinition: { keyFields: false },
    Node: { keyFields: false },
    NodeData: { keyFields: false },
    Edge: { keyFields: false },

    Query: {
      fields: {
        meetings: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['type', 'spaceId'],

          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing, incoming) {
            // If the incoming offset is 0, so we can just return it,
            // no need to merge.
            if (incoming.offset === 0) {
              return incoming;
            }
            // Slicing is necessary because the existing data is
            // immutable, and frozen in development.
            const merged = existing ? existing.meetings.slice(0) : [];
            for (let i = 0; i < incoming.meetings.length; ++i) {
              merged[incoming.offset + i] = incoming.meetings[i];
            }
            return {
              ...incoming,
              meetings: uniqBy(merged, '__ref').filter((m) => m),
            };
          },
        },
      },
    },
  },
});

export async function clearCache(): Promise<void> {
  return cache.reset();
}

export function evictCache(evictOptions: Cache.EvictOptions): boolean {
  return cache.evict(evictOptions);
}

export class ApolloClientFactory {
  private static _client: ApolloClient<NormalizedCacheObject>;
  private static _cachePersistor: CachePersistor<NormalizedCacheObject>;

  public static async getClient(): Promise<
    ApolloClient<NormalizedCacheObject>
  > {
    if (!this._client) {
      // await before instantiating ApolloClient, else queries might run before the cache is persisted
      this._cachePersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        trigger: 'write',
      });
      await this._cachePersistor.restore();

      // Continue setting up Apollo as usual.
      this._client = new ApolloClient({
        link: from([errorLink, splitLink]),
        cache,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all',
          },
          query: {
            errorPolicy: 'all',
          },
        },
        connectToDevTools: !isProduction(),
      });
    }

    return this._client;
  }

  public static async exterminate(): Promise<void> {
    await wsClient.dispose();

    if (this._client) {
      this._cachePersistor.pause();
      await this._client.clearStore();
      await this._cachePersistor.purge();
      this._client.stop();
    }
  }
}
